import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { setUUIDAction, setLoginResponse, setSessionId } from '../../redux/reducers/activityCheckIn';
import { submitActivityLogin } from '../../services/activityCheckInService';
import { setPath } from '../../redux/reducers/route';
import KioskHeader from '../KioskHeader/KioskHeader';
import { GetValueByKey } from '../../services/vocabularyService';
import { setOrigin } from "../../redux/reducers/additionalAssesments";
import { v4 as uuidv4 } from 'uuid';
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";
import moment from 'moment';
import ActivityCheckInLockedModal from './ActivityCheckInLockedModal';
import ActivityCheckInNoteModal from './ActivityCheckInNoteModal';
import { isValidEmail } from '../../helpers/validators';


function ActivityCheckInLogin() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const { groupId, allowAnonymousActivityLogin } = useSelector((state) => state.organization.config);
  const localVocabs = useSelector((state) => state.vocabularies.local);
  const jwt = useSelector((state) => state.apiAuthentication.token);

  const [UUID, setUUID] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showLockModal, setShowLockModal] = useState(false)
  const [showNoteModal, setShowNoteModal] = useState(false)
  const [activeParticipant, setActiveParticipant] = useState(undefined)


  function transformPhone(phone) {
    return `+1${phone.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+1', '')
      .replaceAll('+', '')}`;
  }

  function isValidPhone(phone) {
    const intRegex = /[0-9 -()+]+$/;
    if ((phone.length < 6) || (!intRegex.test(phone))) {
      return false;
    }
    return true;
  }

  function ValidateInput(value) {
    if ((UUID === '0000' && allowAnonymousActivityLogin) || isValidPhone(value) || isValidEmail(value)) {
      return true;
    }
    return false;
  }
  async function login(e) {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    setActiveParticipant(undefined)

    if (ValidateInput(UUID)) {
      const _UUID = isValidPhone(UUID) ? transformPhone(UUID) : UUID;
      setUUID(_UUID);

      // Submit form data
      try {
        let data = null;
        let hasSignInNote = false
        if (_UUID !== '0000' || !allowAnonymousActivityLogin) {
          const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
          data = await submitActivityLogin(groupId, _UUID, localJWT);

          if(!!data.settings){
            if(data.settings.locked){
                if(!data.settings.lockExpiry || moment() < moment.utc(data.settings.lockExpiry).local()){
                  setActiveParticipant(data)
                  setShowLockModal(true)
                  setLoading(false);
                  return
                }
        
            }

            if(!!data.settings.showSignInNote && !!data.settings.signInNote){
              hasSignInNote = true
              setShowNoteModal(true)
              setActiveParticipant(data)
            }
          }
        }

        let successMessage = GetValueByKey(localVocabs, 'activity-check-in-login-success');
        if (_UUID === '0000' && allowAnonymousActivityLogin) {
          successMessage = GetValueByKey(localVocabs, 'activity-check-in-login-skipped');
        }

        addToast(
          successMessage,
          {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
        dispatch(setSessionId(uuidv4()));
        dispatch(setUUIDAction(_UUID));
        dispatch(setLoginResponse(data));
        dispatch(setOrigin("Activity-Check-In"));
        setLoading(false);

        if(!hasSignInNote){
          dispatch(setPath('activity-check-in/select'));
        }
        
      }
      catch (error) {
        // Log error
        console.error(error);

        let message = GetValueByKey(localVocabs, 'activity-check-in-login-failed');
        if (error.message.toLowerCase().includes('not found')) {
          message = GetValueByKey(localVocabs, 'activity-check-in-login-not-found');
        }
        else if (error.message.toLowerCase().includes('not authorized')) {
          message = GetValueByKey(localVocabs, 'activity-check-in-login-not-authorized');
        }

        addToast(
          message,
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );

        setLoading(false);
      }
    }
    else {
      setErrors({ input: 'Please enter a valid email address or phone number?' });
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={login} noValidate>
      <ActivityCheckInLockedModal 
        showModal={showLockModal} 
        onHideModal={() => {
          setActiveParticipant(undefined)
          setShowLockModal(false)
        }} 
        participant={activeParticipant}
      />
      <ActivityCheckInNoteModal 
        showModal={showNoteModal}
        onHideModal={() => {          
          setShowNoteModal(false)
          setActiveParticipant(undefined)
          dispatch(setPath('activity-check-in/select'));
        }}
        participant={activeParticipant}
      />
      <KioskHeader />

      <br />

      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              UUID

              <div className="description-text">
                {GetValueByKey(localVocabs, 'activity-check-in-login-helptext-1')}
              </div>
              {
                allowAnonymousActivityLogin
                && (
                  <div className="description-text">
                    {GetValueByKey(localVocabs, 'activity-check-in-login-helptext-2')}
                  </div>
                )
              }

              <Form.Group className="mt-3 mb-4">
                <Form.Control
                  type="text"
                  placeholder="john@domain.com"
                  value={UUID}
                  onChange={(e) => {
                    setUUID(e.target.value);
                    setErrors({});
                  }}
                  disabled={loading}
                  isInvalid={!!errors.input}
                />
                <Form.Control.Feedback type="invalid">{errors.input}</Form.Control.Feedback>
              </Form.Group>       
              <Button
                disabled={!UUID}
                variant="primary"
                type="submit"
              >
                {
                  loading
                  && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: 10, height: 22, width: 22 }}
                    />
                  )
                }
                Login
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Form>
  );
}

export default ActivityCheckInLogin;
