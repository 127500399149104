/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ToastContainer = (props) => (
  <DefaultToastContainer
    className="toast-container"
    style={{ zIndex: 9999 }}
    {...props}
  />
);

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ToastProvider components={{ ToastContainer }}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ToastProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
