import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Row,
  Button,
  Spinner,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import AutoComplete from 'react-google-autocomplete';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import { setPath } from '../../redux/reducers/route';
import KioskHeader from '../KioskHeader/KioskHeader';
import CustomErrorFeedback from '../CustomErrorFeedback/CustomErrorFeedback';
import submitBriefIntake from '../../services/briefIntakeService';
import RequiredIndicator from '../RequiredIndicator/RequiredIndicator';
import { GetValueByKey } from '../../services/vocabularyService';
import CustomMultiSelect from '../CustomMultiSelect/CustomMultiSelect';
import { IsRequired_BriefIntakeField } from '../../services/organizationService';
import { setSessionId } from '../../redux/reducers/activityCheckIn';
import { setDrupalResponse, setOrigin } from "../../redux/reducers/additionalAssesments";
import 'react-phone-input-2/lib/style.css';
import { v4 as uuidv4 } from 'uuid';
import { GetAdditionalAsessmentConfig } from "../../services/additionalAssessmentConfigService";
import AdditionalAssessmentModal from "../AdditionalAssesments/AdditionalAssessmentsModal";
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";
import submitLog from '../../services/loggerService';
import { getServiceConsentHelpLabel, getServiceConsentLabel } from '../../helpers/intakeConsentHelper';
import { isValidEmail } from '../../helpers/validators';

function KioskBriefIntake() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const googlePlaceApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const terms = useSelector((state) => state.vocabularies.remote);
  const { groupId, code } = useSelector((state) => state.organization.config);
  const localVocabs = useSelector((state) => state.vocabularies.local);
  const jwt = useSelector((state) => state.apiAuthentication.token);
  const intakeCustomizations = useSelector(
    (state) => state.organization.intakeCustomizations?.filter((x) => x.intake === 'kiosk-brief-intake'),
  );  
  const location = useSelector((state) => state.organization.activeLocation)
  const locationName = useSelector((state) => state.organization.activeLocationName)

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [genderIdentity, setGenderIdentity] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [pronounsOther, setPronounsOther] = useState('');
  const [howHearAboutOrg, setHowHearAboutOrg] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [race, setRace] = useState([]);
  const [ethnicity, setEthnicity] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [housingStatus, setHousingStatus] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [justiceSystemInvolvement, setJusticeSystemInvolvement] = useState('');
  const [selectAllThatApply, setSelectAllThatApply] = useState([]);
  const [address, setAddress] = useState('');
  const [addressFull, setAddressFull] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [veteranStatus, setVeteranStatus] = useState('');
  const [personalIncomeLevel, setPersonalIncomeLevel] = useState('');
  const [consentForServicesAndData, setConsentForServicesAndData] = useState('');
  const [consentForDataToBeShared, setConsentForDataToBeShared] = useState('');

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(moment().utc());

  const [addiionalAssessmentsConfigs, setAdditionalAssessmentsConfigs] = useState([]);
  const { id } = useSelector((state) => state.organization.config);
  const [showAdditionalAssessments, setShowAdditionalAssessments] = useState(false);

  useEffect(() => {
    setStartTime(moment().utc());
    GetAdditionalAssessmentsConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAge(dob) {
    const today = new Date();
    const nowYear = today.getFullYear();
    const nowMonth = today.getMonth();
    const nowDay = today.getDate();

    const birthYear = dob.getFullYear();
    const birthMonth = dob.getMonth();
    const birthDay = dob.getDate();

    let age = nowYear - birthYear;
    const age_month = nowMonth - birthMonth;
    const age_day = nowDay - birthDay;

    if (age_month < 0 || (age_month === 0 && age_day < 0)) {
      age = parseInt(age) - 1;
    }

    return age;
  }

  function validateForm() {
    const validationErrors = {};
    const requiredMessage = 'This field is required';
    const selectionRequiredMessage = 'A selection is required';
    const ageValidationError = `It appears as if you may be under the age of 13. 
      Please see a staff member for additional support`;
    const addressSelectionRequiredMessage = 'Please select your address from the auto-complete drop down to continue';
    const invalidMessage = (type) => `Invalid ${type}`;

    // veteranStatus
    if (veteranStatus === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'veteranStatus')) {
      validationErrors.veteranStatus = requiredMessage;
    }

    // personalIncomeLevel
    if (personalIncomeLevel === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'personalIncomeLevel')) {
      validationErrors.personalIncomeLevel = requiredMessage;
    }

    // firstName
    if (firstName === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'firstName')) {
      validationErrors.firstName = requiredMessage;
    }

    // lastName
    if (lastName === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'lastName')) {
      validationErrors.lastName = requiredMessage;
    }

    // email
    if (email === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'email')) {
      validationErrors.email = requiredMessage;
    }
    else if (!isValidEmail(email)) {
      validationErrors.email = invalidMessage('email address');
    }

    // phone
    if (phone === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'phone')) {
      validationErrors.phone = requiredMessage;
    }

    // genderIdentity
    if (genderIdentity === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'genderIdentity')) {
      validationErrors.genderIdentity = requiredMessage;
    }

    // pronouns
    if (pronouns === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'pronouns')) {
      validationErrors.pronouns = requiredMessage;
    }

    // pronouns  -other
    if (pronouns === 'Other' && pronounsOther === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'pronouns_other')) {
      validationErrors.pronounsOther = requiredMessage;
    }

    // howHearAboutOrg
    if (howHearAboutOrg === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'howHearAboutOrg')) {
      validationErrors.howHearAboutOrg = requiredMessage;
    }

    // organizationName
    const orgNameRequired = (howHearAboutOrg !== '15' && howHearAboutOrg !== '369');
    if (orgNameRequired && organizationName === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'organizationName')) {
      validationErrors.organizationName = requiredMessage;
    }

    // race
    if (race.length === 0 && IsRequired_BriefIntakeField(intakeCustomizations, 'race')) {
      validationErrors.race = selectionRequiredMessage;
    }

    // ethnicity
    if (ethnicity === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'ethnicity')) {
      validationErrors.ethnicity = requiredMessage;
    }

    // dateOfBirth
    if (dateOfBirth === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'dateOfBirth')) {
      validationErrors.dateOfBirth = requiredMessage;
    }
    else if (getAge(new Date(dateOfBirth)) < 13 && dateOfBirth !== '') {
      validationErrors.dateOfBirth = ageValidationError;
    }

    // employmentStatus
    if (employmentStatus === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'employmentStatus')) {
      validationErrors.employmentStatus = requiredMessage;
    }

    // housingStatus
    if (housingStatus === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'housingStatus')) {
      validationErrors.housingStatus = requiredMessage;
    }

    // educationLevel
    if (educationLevel === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'educationLevel')) {
      validationErrors.educationLevel = requiredMessage;
    }

    // justiceSystemInvolvement
    if (justiceSystemInvolvement === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'justiceSystemInvolvement')) {
      validationErrors.justiceSystemInvolvement = requiredMessage;
    }

    // address
    if (addressFull === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'address')) {
      validationErrors.address = addressSelectionRequiredMessage;
    }

    // selectAllThatApply
    if (selectAllThatApply?.length === 0 && IsRequired_BriefIntakeField(intakeCustomizations, 'selectAllThatApply')) {
      validationErrors.selectAllThatApply = selectionRequiredMessage;
    }

    // additionalInfo
    if (additionalInfo === '' && IsRequired_BriefIntakeField(intakeCustomizations, 'additionalInfo')) {
      validationErrors.additionalInfo = requiredMessage;
    }

    // consentForServicesAndData
    if (!consentForServicesAndData && IsRequired_BriefIntakeField(intakeCustomizations, 'consentForServicesAndData')) {
      validationErrors.consentForServicesAndData = requiredMessage;
    }

    // consentForDataToBeShared
    if (!consentForDataToBeShared && IsRequired_BriefIntakeField(intakeCustomizations, 'consentForDataToBeShared')) {
      validationErrors.consentForDataToBeShared = requiredMessage;
    }

    setErrors(validationErrors); // Update state var
    return Object.keys(validationErrors).length === 0; // Return validation result
  }

  function calcEndTime() {
    const startTimeMinutesSinceMidnight = startTime.diff(moment().utc().startOf('day'), 'seconds');
    const actualEndTimeMinutesSinceMidnight = moment().utc().diff(moment().utc().startOf('day'), 'seconds');

    // Limit duration to 8 Minutes minimum
    if ((actualEndTimeMinutesSinceMidnight - startTimeMinutesSinceMidnight) / 60 < 8) {
      const cappedEndTime = startTimeMinutesSinceMidnight + (8 * 60);
      return cappedEndTime;
    }

    return actualEndTimeMinutesSinceMidnight;
  }

  function buildRequestBody() {
    const data = {
      field_case_name: {
        given: firstName,
        family: lastName,
      },
      field_veteran_status: {
        value: veteranStatus
      },
      field_personal_income_level: {
        value: personalIncomeLevel
      },
      field_intake_source: {
        target_id: howHearAboutOrg || 0,
      },
      
      field_case_pronouns: {
        value: pronouns === 'Other' ? pronounsOther : pronouns,
      },
      field_demo_age: {
        value: dateOfBirth, //Note: this field should always be required for in-takes
      },
      field_demo_status_employment: {
        target_id: employmentStatus,
      },
      field_demo_status_housing: {
        target_id: housingStatus,
      },
      field_demo_education_level: {
        target_id: educationLevel,
      },
      field_demo_status_justice: {
        target_id: justiceSystemInvolvement,
      },
      field_parent_involvement: [],
      field_address: {
        field_address: {
          address_line1: `${addressFull?.address_components?.find((x) => x.types.includes('street_number'))?.long_name} `
            + `${addressFull?.address_components?.find((x) => x.types.includes('route'))?.long_name}`,
          address_line2: '',
          locality: addressFull?.address_components?.find((x) => x.types.includes('locality'))?.long_name,
          administrative_area: addressFull?.address_components?.find((x) => x.types.includes('administrative_area_level_1'))?.short_name,
          postal_code: addressFull?.address_components?.find((x) => x.types.includes('postal_code'))?.long_name,
          country_code: addressFull?.address_components?.find((x) => x.types.includes('country'))?.short_name,
        },
        field_address_primary: {
          value: 1,
        },
      },
      field_case_user_note: {
        value: additionalInfo,
      },
      field_session_engagement: {
        field_engagement_channel: {
          value: 'Other',
        },
        field_engagement_date: {
          value: moment().utc().format('YYYY-MM-DD'),
        },
        field_engagement_time: {
          from: startTime.diff(moment().utc().startOf('day'), 'seconds'),
          to: calcEndTime(),
        },
      },
    };

    if (race.length > 0) {
      data.field_demo_race = [
        race.map((value) => ({
          target_id: value
        })),
      ]
    }
    
    if(ethnicity !== ""){
      data.field_demo_latinx_origin = {
        target_id: ethnicity
      }
    }

    if(genderIdentity !== ""){
      data.field_demo_gender = {
        target_id: genderIdentity,
      }
    }


    if (email !== '') {
      data.field_case_email_address = {
        value: email,
      };
    }

    data.data_consent = {
      field_case_phi_consent: {
        value: consentForServicesAndData !== '' ? consentForServicesAndData : null
      },
      field_case_phi_share: {
        value: consentForDataToBeShared !== '' ? consentForDataToBeShared : null
      }
    }

    if (phone !== '') {
      data.field_case_phone = {
        field_phone_number: {
          value: `+1${phone.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+1', '')
            .replaceAll('+1', '')}`,
        },
        field_phone_primary: {
          value: 1,
        },
      };
    }

    if (organizationName !== '') {
      data.field_service_provider = {
        value: organizationName,
      };
    }

    if (selectAllThatApply.includes('involvedInDrugDuiRecoveryCourt')) {
      data.field_parent_involvement.push(
        {
          target_id: 307,
        },
      );
    }

    if (selectAllThatApply.includes('involvedInChildFamilyProtectiveServices')) {
      data.field_parent_involvement.push(
        {
          target_id: 308,
        },
      );
    }

    // [OPTIONAL] additionalInfo
    if (additionalInfo) {
      data.field_case_user_note = {
        value: additionalInfo,
      };
    }

    data.field_service_provider_location_id = {value: location}
    data.field_service_provider_location_name = {value: locationName}

    return data;
  }
  const GetAdditionalAssessmentsConfigs = async () => {
    const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
    const data = await GetAdditionalAsessmentConfig(id, localJWT);
    setAdditionalAssessmentsConfigs(data);
  };
  function redirectToHome() {
    dispatch(setPath('home'));
  }
  async function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    if (validateForm()) {
      // Extra validation
      if (!phone && !email) {
        addToast(
          'Please supply either a phone number or email address to complete this form.',
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
        setLoading(false);
        return;
      }

      if(!consentForServicesAndData || consentForServicesAndData === "false"){
        addToast(
          "We are unable to complete your intake without a valid consent to store your data electronically.",
          {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 10000,
          }
        );
        let validationErrors = {}
        validationErrors.consentForServicesAndData = "We are unable to complete your intake without a valid consent to store your data electronically.";
        setErrors(validationErrors);
        
        setLoading(false);
        return;
      }

      // Submit form data
      try {
        const data = buildRequestBody();
        const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
        const res = await submitBriefIntake(data, groupId, localJWT);

        addToast(
          GetValueByKey(localVocabs, 'kiosk-brief-intake-success'),
          {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
        dispatch(setSessionId(uuidv4()));
        dispatch(setDrupalResponse(res));
        dispatch(setOrigin("Kiosk-Brief-Intake"));
        setLoading(false);
        const allowedSteps = addiionalAssessmentsConfigs?.map(i => i.step);
        if (addiionalAssessmentsConfigs.length > 0 && allowedSteps[0].find(i => i === "Kiosk Brief Intake")) {
          setShowAdditionalAssessments(true);
        }
        else {
          redirectToHome();
        }
      }
      catch (error) {
        // Log error
        console.error(error);
        let errMessage = [GetValueByKey(localVocabs, 'kiosk-brief-intake-failed')];

        //Send log to API
        submitLog(groupId, location, undefined, undefined, "Brief Intake failure", error.message, jwt).catch((error) => {console.error("Unable to submit UI log ", error)})

        // Relay message from Drupal API or show default
        if (error.message.toLowerCase().includes('must be unique') || error.message.toLowerCase().includes('already in use')) {
          errMessage = [];
          if (error.message.toLowerCase().includes('phone')) {
            errMessage.push(GetValueByKey(localVocabs, 'kiosk-brief-intake-duplicate-phone-error'));
          }
          if (error.message.toLowerCase().includes('email')) {
            errMessage.push(GetValueByKey(localVocabs, 'kiosk-brief-intake-duplicate-email-error'));
          }
        }

        errMessage.forEach((msg) => {
          addToast(
            msg,
            {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 15000,
            },
          );
        });

        setLoading(false);
      }
    }
    else {
      addToast(
        GetValueByKey(localVocabs, 'kiosk-brief-intake-form-errors'),
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );

      setLoading(false);
    }
  }

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  return (
    <div>
      <KioskHeader />
      <br />

      <Form className="intake-form" onSubmit={submitForm} noValidate>
        <h5>Kiosk Brief Intake</h5>
        <div className="description-text">
          You have selected you would like to receive peer support. Please fill out the
          following information and let staff know when you are finished. If you do not wish to
          receive peer support, please do not fill out this information. By filling out this
          information, you agree to receive peer support from the organization and provide consent
          to being contacted by a peer.
        </div>
        <br />

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'firstName')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'firstName')
                      && <RequiredIndicator />
                    }
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value); clearError('firstName'); }}
                    isInvalid={!!errors.firstName}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          {
            intakeCustomizations.find((x) => x.field === 'lastName')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'lastName')
                      && <RequiredIndicator />
                    }
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value); clearError('lastName'); }}
                    isInvalid={!!errors.lastName}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'email')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'email')
                      && <RequiredIndicator />
                    }
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); clearError('email'); }}
                    isInvalid={!!errors.email}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
          {
            intakeCustomizations.find((x) => x.field === 'phone')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'phone')
                      && <RequiredIndicator />
                    }
                    Phone
                  </Form.Label>
                  <PhoneInput
                    value={phone}
                    onlyCountries={['us']}
                    country="us"
                    disableCountryCode
                    placeholder=""
                    disableDropdown
                    inputStyle={{
                      width: '100%',
                      height: 38,
                      borderRadius: 3,
                      borderColor: errors.phone ? 'red' : '',
                    }}
                    onChange={(value) => {
                      setPhone(value);
                      clearError('phone');
                    }}
                    disabled={loading}
                  />
                  <CustomErrorFeedback message={errors.phone} />
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'genderIdentity')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'genderIdentity')
                      && <RequiredIndicator />
                    }
                    Gender Identity
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={genderIdentity}
                    onChange={(e) => { setGenderIdentity(e.target.value); clearError('genderIdentity'); }}
                    isInvalid={!!errors.genderIdentity}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.gender
                      && Object.keys(terms.gender).map((key) => (
                        <option key={terms.gender[key].tid} value={terms.gender[key].tid}>
                          {terms.gender[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.genderIdentity}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'pronouns')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'pronouns')
                      && <RequiredIndicator />
                    }
                    Pronouns
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={pronouns}
                    onChange={(e) => { setPronouns(e.target.value); clearError('pronouns'); }}
                    isInvalid={!!errors.pronouns}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.pronouns
                      && Object.keys(terms.pronouns).map((key) => (
                        <option key={key} value={terms.pronouns[key]}>
                          {terms.pronouns[key]}
                        </option>
                      ))
                    }
                    <option value="Other">
                      Other
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.pronouns}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
          {
            pronouns === 'Other'
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    <RequiredIndicator />
                    Pronouns - Other
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please specify here"
                    value={pronounsOther}
                    onChange={(e) => { setPronounsOther(e.target.value); clearError('pronounsOther'); }}
                    isInvalid={!!errors.pronounsOther}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.pronounsOther}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          }
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'howHearAboutOrg')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'howHearAboutOrg')
                      && <RequiredIndicator />
                    }
                    How did you hear about our organization?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={howHearAboutOrg}
                    onChange={(e) => { setHowHearAboutOrg(e.target.value); clearError('howHearAboutOrg'); }}
                    isInvalid={!!errors.howHearAboutOrg}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.intake_source
                      && Object.keys(terms.intake_source).map((key) => (
                        <option key={terms.intake_source[key].tid} value={terms.intake_source[key].tid}>
                          {terms.intake_source[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.howHearAboutOrg}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
          {
            (howHearAboutOrg !== '' && howHearAboutOrg !== '15' && howHearAboutOrg !== '369')
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    <RequiredIndicator />
                    Organization Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={organizationName}
                    onChange={(e) => { setOrganizationName(e.target.value); clearError('organizationName'); }}
                    isInvalid={!!errors.organizationName}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.organizationName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          }
        </Row>

        {
          intakeCustomizations.find((x) => x.field === 'race')?.show
          && (
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'race')
                      && <RequiredIndicator />
                    }
                    Race
                  </Form.Label>
                  <CustomMultiSelect
                    options={!race ? [] : Object.keys(terms.race).map((key) => ({
                      label: terms.race[key].name,
                      key: terms.race[key].tid,
                    }))}
                    value={race}
                    onChange={(value) => {
                      setRace(value);
                      clearError('race');
                    }}
                    columns={3}
                    error={errors.race}
                  />
                  <CustomErrorFeedback message={errors.race} />
                </Form.Group>
              </Col>
            </Row>)}
        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'ethnicity')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'ethnicity')
                      && <RequiredIndicator />
                    }
                    Ethnicity
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={ethnicity}
                    onChange={(e) => { setEthnicity(e.target.value); clearError('ethnicity'); }}
                    isInvalid={!!errors.ethnicity}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.hispanic_latinx_or_spanish_origi
                      && Object.keys(terms.hispanic_latinx_or_spanish_origi).map((key) => (
                        <option
                          key={terms.hispanic_latinx_or_spanish_origi[key].tid}
                          value={terms.hispanic_latinx_or_spanish_origi[key].tid}
                        >
                          {terms.hispanic_latinx_or_spanish_origi[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.ethnicity}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}

          {
            intakeCustomizations.find((x) => x.field === 'dateOfBirth')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'dateOfBirth')
                      && <RequiredIndicator />
                    }
                    Date of Birth
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => { setDateOfBirth(e.target.value); clearError('dateOfBirth'); }}
                    isInvalid={!!errors.dateOfBirth}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.dateOfBirth}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'employmentStatus')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'employmentStatus')
                      && <RequiredIndicator />
                    }
                    Current Employment Status
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={employmentStatus}
                    onChange={(e) => { setEmploymentStatus(e.target.value); clearError('employmentStatus'); }}
                    isInvalid={!!errors.employmentStatus}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.status_employment
                      && Object.keys(terms.status_employment).map((key) => (
                        <option key={terms.status_employment[key].tid} value={terms.status_employment[key].tid}>
                          {terms.status_employment[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.employmentStatus}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
          {
            intakeCustomizations.find((x) => x.field === 'personalIncomeLevel')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'personalIncomeLevel')
                      && <RequiredIndicator />
                    }
                    Current Personal Income
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={personalIncomeLevel}
                    onChange={(e) => setPersonalIncomeLevel(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.personalIncomeLevel}
                  >
                    <option value="">-- Select --</option>
                    <option value="$1-$9,999">$1-$9,999</option>
                    <option value="$10,000-$19,999">$10,000-$19,999</option>
                    <option value="$20,000-$29,999">$20,000-$29,999</option>
                    <option value="$30,000-$39,999">$30,000-$39,999</option>
                    <option value="$40,000-$49,999">$40,000-$49,999</option>
                    <option value="$50,000+">$50,000+</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.personalIncomeLevel}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'housingStatus')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'housingStatus')
                      && <RequiredIndicator />
                    }
                    Current Housing Status
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={housingStatus}
                    onChange={(e) => { setHousingStatus(e.target.value); clearError('housingStatus'); }}
                    isInvalid={!!errors.housingStatus}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.status_housing
                      && Object.keys(terms.status_housing).map((key) => (
                        <option key={terms.status_housing[key].tid} value={terms.status_housing[key].tid}>
                          {terms.status_housing[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.housingStatus}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
          {
            intakeCustomizations.find((x) => x.field === 'educationLevel')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'educationLevel')
                      && <RequiredIndicator />
                    }
                    Current Education Level
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={educationLevel}
                    onChange={(e) => { setEducationLevel(e.target.value); clearError('educationLevel'); }}
                    isInvalid={!!errors.educationLevel}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.education_level
                      && Object.keys(terms.education_level).map((key) => (
                        <option key={terms.education_level[key].tid} value={terms.education_level[key].tid}>
                          {terms.education_level[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.educationLevel}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}

        </Row>
        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'veteranStatus')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'veteranStatus')
                      && <RequiredIndicator />
                    }
                    U.S. Military Veteran?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={veteranStatus}
                    onChange={(e) => setVeteranStatus(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.veteranStatus}
                  >
                    <option value="" >-- Select --</option>
                    <option value="Yes" >Yes</option>
                    <option value="No" >No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.veteranStatus}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}

          {
            intakeCustomizations.find((x) => x.field === 'justiceSystemInvolvement')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'justiceSystemInvolvement')
                      && <RequiredIndicator />
                    }
                    Current Justice System Involvement
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={justiceSystemInvolvement}
                    onChange={(e) => { setJusticeSystemInvolvement(e.target.value); clearError('justiceSystemInvolvement'); }}
                    isInvalid={!!errors.justiceSystemInvolvement}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.status_justice
                      && Object.keys(terms.status_justice).map((key) => (
                        <option key={terms.status_justice[key].tid} value={terms.status_justice[key].tid}>
                          {terms.status_justice[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.justiceSystemInvolvement}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'selectAllThatApply')?.show
            && (
              <Col md={6} className="mb-3">
                <Form.Label>
                  {
                    IsRequired_BriefIntakeField(intakeCustomizations, 'selectAllThatApply')
                    && <RequiredIndicator />
                  }
                  Please select all that apply
                </Form.Label>
                <CustomMultiSelect
                  options={[
                    {
                      label: 'Currently involved in Drug/DUI/Recovery court',
                      key: 'involvedInDrugDuiRecoveryCourt',
                    },
                    {
                      label: 'Currently involved in Child/Family protective services',
                      key: 'involvedInChildFamilyProtectiveServices',
                    },
                  ]}
                  value={selectAllThatApply}
                  onChange={(value) => {
                    setSelectAllThatApply(value);
                    clearError('selectAllThatApply');
                  }}
                  error={errors.selectAllThatApply}
                />
                <CustomErrorFeedback message={errors.selectAllThatApply} />
              </Col>)}
        </Row>


        <Row>
          {
            (intakeCustomizations.find((x) => x.field === 'consentForServicesAndData')?.show || intakeCustomizations.find((x) => x.field === 'consentForDataToBeShared')?.show)
            && (
              <Col md={12}>
                <div className="optional-header">
                  {getServiceConsentHelpLabel(code)}
                </div>
              </Col>)}

          {
            intakeCustomizations.find((x) => x.field === 'consentForServicesAndData')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'consentForServicesAndData')
                      && <RequiredIndicator />
                    }
                    {getServiceConsentLabel(code)}
                    
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={consentForServicesAndData}
                    onChange={(e) => setConsentForServicesAndData(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.consentForServicesAndData}
                  >
                    <option value="" >-- Select --</option>
                    <option value="true" >Yes</option>
                    <option value="false" >No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.consentForServicesAndData}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}

          {
            intakeCustomizations.find((x) => x.field === 'consentForDataToBeShared')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'consentForDataToBeShared')
                      && <RequiredIndicator />
                    }
                    Do you provide consent for your personal health information to be shared by this organization if requested for the delivery of services?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={consentForDataToBeShared}
                    onChange={(e) => setConsentForDataToBeShared(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.consentForDataToBeShared}
                  >
                    <option value="" >-- Select --</option>
                    <option value="true" >Yes</option>
                    <option value="false" >No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.consentForDataToBeShared}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'address')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'address')
                      && <RequiredIndicator />
                    }
                    Address (if experiencing homelessness, provide the best address where
                    you might sleep most often)
                  </Form.Label>
                  <AutoComplete
                    required
                    className="placesAutoComplete"
                    style={{ borderColor: errors.address ? 'red' : '' }}
                    apiKey={googlePlaceApiKey}
                    options={{
                      types: ['geocode', 'establishment'],
                    }}
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      clearError('address');
                    }}
                    onPlaceSelected={(place) => {
                      setAddress(place.formatted_address);
                      setAddressFull(place);
                      clearError('address');
                    }}
                    disabled={loading}
                  />
                  <CustomErrorFeedback message={errors.address} />
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'additionalInfo')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_BriefIntakeField(intakeCustomizations, 'additionalInfo')
                      && <RequiredIndicator />
                    }
                    Please include any additional information you wish your peer specialist to know
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={additionalInfo}
                    onChange={(e) => { setAdditionalInfo(e.target.value); clearError('additionalInfo'); }}
                    disabled={loading}
                  />
                  <CustomErrorFeedback message={errors.additionalInfo} />
                </Form.Group>
              </Col>)}
        </Row>

        <Button
          type="submit"
          variant="primary"
        >
          {
            loading
            && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: 10, height: 22, width: 22 }}
              />
            )
          }
          Submit
        </Button>
      </Form>
      <AdditionalAssessmentModal
        show={showAdditionalAssessments}
        additionalAssessmentsConfigs={addiionalAssessmentsConfigs}
        setShow={setShowAdditionalAssessments}
        redirect={redirectToHome}
        name={`${firstName} ${lastName}`}
      />
    </div>
  );
}

export default KioskBriefIntake;
