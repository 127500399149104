import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import { SubmitAdditionalAssestment } from "../../services/additionalFormsService";
import moment from 'moment';
import { useEffect } from 'react';
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";


const RecoveryCapitalAssessmentModal = (props) => {
  const dispatch = useDispatch();
  const { show, setShow, name, loadStatus } = props;
  const location = useSelector((state) => state.organization.activeLocation)
  const locationName = useSelector((state) => state.organization.activeLocationName)

  const [questionA, setQuestionA] = useState();
  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question3, setQuestion3] = useState("");
  const [question4, setQuestion4] = useState("");
  const [question5, setQuestion5] = useState("");
  const [question6, setQuestion6] = useState("");
  const [question7, setQuestion7] = useState("");
  const [question8, setQuestion8] = useState("");
  const [question9, setQuestion9] = useState("");
  const [question10, setQuestion10] = useState("");
  const [errors, setErrors] = useState({});
  const additionalAssessments = useSelector((state) => state.additionalAssessments);
  const activityCheckIn = useSelector((state) => state.activityCheckIn);
  const { groupId } = useSelector((state) => state.organization.config);
  const { addToast } = useToasts();
  const [startTime, setStartTime] = useState(moment().utc());
  const [saving, setSaving] = useState();
  const { sessionId } = useSelector((state) => state.activityCheckIn);
  const { origin } = useSelector((state) => state.additionalAssessments);
  const jwt = useSelector((state) => state.apiAuthentication.token);

  useEffect(() => {
    setQuestionA(setName());
    setStartTime(moment().utc());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setQuestionA(setName());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const setName = () => {
    return origin === "Activity-Check-In" ? activityCheckIn?.loginResponse?.name : name;
  };
  const submitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (validateForm()) {
      try {
        setSaving(true);
        let userId = additionalAssessments.origin === "Activity-Check-In" ?
          activityCheckIn.loginResponse.userId :
          additionalAssessments.id;
        const data = buildRequestBody(userId);
        const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
        await SubmitAdditionalAssestment(data, groupId, localJWT);
        addToast(
          'Recovery Capital Assessment saved',
          {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
        loadStatus(true);
        setSaving(false);
        if (show) setShow(false);
      }
      catch (error) {
        setSaving(false);
        console.log(error);
        addToast(
          'Can not save Recovery Capital Assestment',
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
      }
    }
    else {
      addToast(
        'Please complete all required questions to continue',
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );
    }
  };
  const validateForm = () => {
    const validationErrors = {};
    const requiredField = "This field is required";
    if (!question1) {
      validationErrors.question1 = requiredField;
    }
    if (!question2) {
      validationErrors.question2 = requiredField;
    }
    if (!question3) {
      validationErrors.question3 = requiredField;
    }
    if (!question4) {
      validationErrors.question4 = requiredField;
    }
    if (!question5) {
      validationErrors.question5 = requiredField;
    }
    if (!question6) {
      validationErrors.question6 = requiredField;
    }
    if (!question7) {
      validationErrors.question7 = requiredField;
    }
    if (!question8) {
      validationErrors.question8 = requiredField;
    }
    if (!question9) {
      validationErrors.question9 = requiredField;
    }
    if (!question10) {
      validationErrors.question10 = requiredField;
    }
    if (!questionA) {
      validationErrors.questionA = requiredField;
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const buildRequestBody = (userId) => {
    const data = {
      form_type: "recovery-capital-assessment",
      userId: userId,
      field_important_things: question1,
      field_happiness_life: question2,
      field_energy_assessment: question3,
      field_community_pride: question4,
      field_friends_assessment: question5,
      field_fulfillment: question6,
      field_housing: question7,
      field_responsibility: question8,
      field_happiness_professional: question9,
      field_recovery_journey: question10,
      field_questionA: questionA,
      field_questionB: moment(),
      form_status: "Completed",
      participantId: userId,
      sessionId: sessionId,
      field_session_engagement: {
        field_engagement_channel: {
          "value": "Other"
        },
        field_engagement_date: {
          "value": moment().utc().format('YYYY-MM-DD'),
        },
        field_engagement_time: {
          "from": startTime.diff(moment().utc().startOf('day'), 'seconds'),
          "to": calcEndTime(),
        }
      },
      field_case_user: {
        "target_id": userId
      },
      field_session_context: {
        "value": "self_assessment"
      },
      organization_location_id: location,
      organization_location_name: locationName
    };
    return data;
  };
  const clearError = (key) => {
    delete errors[key];
    setErrors(errors);
  }
  const calcEndTime = () => {
    const startTimeMinutesSinceMidnight = startTime.diff(moment().utc().startOf('day'), 'seconds');
    const actualEndTimeMinutesSinceMidnight = moment().utc().diff(moment().utc().startOf('day'), 'seconds');

    // Limit duration to 8 Minutes minimum
    if ((actualEndTimeMinutesSinceMidnight - startTimeMinutesSinceMidnight) / 60 < 8) {
      const cappedEndTime = startTimeMinutesSinceMidnight + (8 * 60);
      return cappedEndTime;
    }

    return actualEndTimeMinutesSinceMidnight;
  }
  const values = [
    { label: "Strongly Disagree", value: 1 },
    { label: "Disagree", value: 2 },
    { label: "Somewhat Disagree", value: 3 },
    { label: "Somewhat Agree", value: 4 },
    { label: "Agree", value: 5 },
    { label: "Strongly Agree", value: 6 },
  ];
  return (
    <Modal show={show} onHide={() => null} centered size='lg'>
      <Modal.Header>
        <Modal.Title>
          Recovery Capital Assessment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="intake-form" onSubmit={submitForm} noValidate>
          <Col md={12}>
            <Form.Group as={Row}>
              <Form.Label column sm="4">Participant Name:</Form.Label>
              <Col sm="8">
                <Form.Control type="text" value={questionA} isInvalid={!!errors.questionA} onChange={(e) => { setQuestionA(e.target.value); clearError("questionA"); }} disabled />
              </Col>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  1. There are more
                  important things to
                  me in life than using
                  substances
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question1}
                  onChange={(e) => { setQuestion1(e.target.value); clearError('question1'); }}
                  isInvalid={!!errors.question1}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`1.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question1}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  2. In general I am
                  happy with my life
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question2}
                  onChange={(e) => { setQuestion2(e.target.value); clearError('question2'); }}
                  isInvalid={!!errors.question2}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`2.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question2}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  3. I have enough
                  energy to complete
                  the tasks I set for
                  myself
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question3}
                  onChange={(e) => { setQuestion3(e.target.value); clearError('question3'); }}
                  isInvalid={!!errors.question3}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`3.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question3}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  4. I am proud of the
                  community I live in
                  and feel a part of it
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question4}
                  onChange={(e) => { setQuestion4(e.target.value); clearError('question4'); }}
                  isInvalid={!!errors.question4}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`4.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question4}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  5. I get lots of support
                  from friends
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question5}
                  onChange={(e) => { setQuestion5(e.target.value); clearError('question5'); }}
                  isInvalid={!!errors.question5}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`5.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question5}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  6. I regard my life as
                  challenging and
                  fulfilling without the
                  need for using drugs
                  or alcohol
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question6}
                  onChange={(e) => { setQuestion6(e.target.value); clearError('question6'); }}
                  isInvalid={!!errors.question6}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`6.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question6}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  7. My living space has
                  helped to drive my
                  recovery journey
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question7}
                  onChange={(e) => { setQuestion7(e.target.value); clearError('question7'); }}
                  isInvalid={!!errors.question7}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`7.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question7}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  8. I take full
                  responsibility for my
                  actions
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question8}
                  onChange={(e) => { setQuestion8(e.target.value); clearError('question8'); }}
                  isInvalid={!!errors.question8}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`8.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question8}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  9. I am happy dealing
                  with a range of
                  professional people
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question9}
                  onChange={(e) => { setQuestion9(e.target.value); clearError('question9'); }}
                  isInvalid={!!errors.question9}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`9.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question9}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group as={Row}>
              <Col md={7}>
                <Form.Label className='left-indent'>
                  10. I am making good
                  progress on my
                  recovery journey
                </Form.Label>
              </Col>
              <Col md={5}>
                <Form.Control
                  as="select"
                  value={question10}
                  onChange={(e) => { setQuestion10(e.target.value); clearError('question10'); }}
                  isInvalid={!!errors.question10}
                >
                  <option className='text-position' value="" disabled>-- Select --</option>
                  {
                    values.map((i, index) => (
                      <option key={`10.${index}`} value={i.value}>
                        {i.label}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Form.Control.Feedback type="invalid">{errors.question10}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Button
            style={{ marginTop: '1rem' }}
            type="submit"
          >
            {
              saving
              && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: 10, height: 22, width: 22 }}
                />
              )
            }
            Submit
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setErrors({});
              if (show) setShow(false);
            }}
            style={{ marginTop: '1rem', marginLeft: '1rem ' }}
            disabled={saving}
          >
            Cancel
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>


      </Modal.Footer>
    </Modal>
  );
}

export default RecoveryCapitalAssessmentModal;
