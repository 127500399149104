import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import KioskHeader from '../KioskHeader/KioskHeader';
import { submitActivitySelection } from '../../services/activityCheckInService';
import { setPath } from '../../redux/reducers/route';
import { GetByGroupKey, GetByKey, GetValueByKey } from '../../services/vocabularyService';
import RequiredIndicator from '../RequiredIndicator/RequiredIndicator';
import { GetAdditionalAsessmentConfig } from "../../services/additionalAssessmentConfigService";
import AdditionalAssessmentModal from '../AdditionalAssesments/AdditionalAssessmentsModal';
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";
import ActivityCheckInSelectItem from './ActivityCheckInSelectItem';


function ActivityCheckInSelect() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const nid = useSelector((state) => state.activityCheckIn.loginResponse?.userId) || 0;
  const participantType = useSelector((state) => state.activityCheckIn.loginResponse?.type);
  const localVocabs = useSelector((state) => state.vocabularies.local);
  const jwt = useSelector((state) => state.apiAuthentication.token);
  const actionMappings = useSelector((state) => state.actionMappings.actionMappings);

  const [activitySelection, setActivitySelection] = useState([])

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useSelector((state) => state.organization.config);
  const location = useSelector((state) => state.organization.activeLocation)

  const [showAdditionalAssessments, setShowAdditionalAssessments] = useState(false);
  const [addiionalAssessmentsConfigs, setAdditionalAssessmentsConfigs] = useState([]);

  useEffect(() => {
    GetAdditionalAssessmentsConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAdditionalAssessmentsConfigs = async () => {
    const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
    const data = await GetAdditionalAsessmentConfig(id, localJWT);
    setAdditionalAssessmentsConfigs(data);
  };
  function validateForm() {
    const validationErrors = {};
    const requiredMessage = 'This field is required';
    const childActivityRequiredMessage = 'Please select at least one sub option'


    //Make sure there is a selection
    if(activitySelection.length === 0){
      validationErrors.noSelection = "Please select at least one option"
    }

    //Make sure all selected parents have a child selected
    var selectedParents = activitySelection.filter(activity => activity.isParent)
   
    selectedParents.forEach(parentActivity => {
      const children = localVocabs.filter((x) => x.parentId === GetByKey(localVocabs, parentActivity.key)?.id)
      const selectedChildren = activitySelection.filter(activity => children.some(child => child.key === activity.key))
      if(selectedChildren.length === 0 ){
        validationErrors[parentActivity.key] = childActivityRequiredMessage
      }
    })

    //Other validation
    const otherOption = activitySelection.find(x => x.key === "activity-check-in-option-other")
    if(!!otherOption && !otherOption.other){
      validationErrors.otherSpecification = requiredMessage
    }


    setErrors(validationErrors); // Update state var
    return Object.keys(validationErrors).length === 0; // Return validation result
  }
  function BuildRequestModel() {
    const options = GetByGroupKey(localVocabs, 'activity-check-in-options')

    let model = {
      organizationId: id,
      participantId: nid || 0,
      organizationLocationId: !!location ? location : undefined
    }

    const selectedActivities = []
    activitySelection.forEach(x => {
      if(!options.find(option => option.parentId === x.id)){
        selectedActivities.push({activity: x.key, activityOther: x.other})
      }
    })
    model.selectedActivities = selectedActivities

    return model
  }

  function validateMappedOption(activity) {
    const result = {
      allowSubmit: false,
      successMessageKey: '',
      failureMessageKey: 'activity-check-in-submit-failed',
    };
    
    const actionMapping = actionMappings.find((x) => x.sourceAction === activity);
    if (actionMapping) {
      // Validate action
      if (actionMapping.criteria.includes(participantType)) {
        result.allowSubmit = true;
        result.successMessageKey = actionMapping.successTargetAction;
      }
      else {
        result.allowSubmit = false;
        result.failureMessageKey = actionMapping.failureTargetAction;
      }
    }

    return result;
  }

  function validateDefaultOption(activity) {
    const result = {
      allowSubmit: true,
      successMessageKey: 'activity-check-in-submit-success',
      failureMessageKey: '',
    };

    // Validation for "Meet with peer specialist"
    if (activity === 'activity-check-in-option-3') {
      const criteria = ['comprehensive', 'brief_checkin']; // participant types that may check-in for this activity
      if (criteria.includes(participantType)) {
        result.successMessageKey = 'activity-check-in-submit-success-ps-acu';
      }
      else {
        result.allowSubmit = false;
        result.failureMessageKey = 'activity-check-in-submit-failed-ps-rcc';
      }
    }
    

    return result;
  }

  async function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    if (validateForm()) {
      try {
        const activityValidationResult = activitySelection.map((selectedActivity) => {return actionMappings
          .map((x) => x.sourceAction).includes(selectedActivity)
          ? validateMappedOption(selectedActivity) : validateDefaultOption(selectedActivity)
        });

        const allowSubmit = !activityValidationResult.some(result => !result.allowSubmit)

        if (allowSubmit) {
          const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
          const model = BuildRequestModel();
          await submitActivitySelection(model, localJWT);

          const messages = [...new Set(activityValidationResult.map(x => x.successMessageKey))]
          messages.forEach(message => {
            addToast(
              GetValueByKey(localVocabs, message),
              {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 10000,
              },
            );
          })


          setLoading(false);
          const allowedSteps = addiionalAssessmentsConfigs?.map(i => i.step);
          if (addiionalAssessmentsConfigs?.length > 0 && nid !== 0 && allowedSteps[0].find(i => i === "Activity Check In")) {
            setShowAdditionalAssessments(true);
          } else {
            dispatch(setPath('home'));
          }
        }
        else {
          const messages = [...new Set(activityValidationResult.map(x => x.failureMessageKey))]
          messages.forEach(message => {
            addToast(
              GetValueByKey(localVocabs, message),
              {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 10000,
              },
            );
          })


          setLoading(false);
        }
      }
      catch (error) {
        // Log error
        console.error(error);
        addToast(
          GetValueByKey(localVocabs, 'activity-check-in-submit-failed'),
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );

        setLoading(false);
      }
    }
    else {
      addToast(
        GetValueByKey(localVocabs, 'activity-check-in-form-errors'),
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );

      setLoading(false);
    }
  }

  function redirect() {
    dispatch(setPath('home'));

  }

  const toggleActivitySelection = (options) => {
    let newSelection = [...activitySelection]

    options.forEach(option => {
      if(newSelection.find( x=> x.key === option.key)){
        newSelection = newSelection.filter(x => x.key !== option.key)
      }else{
        newSelection.push({key: option.key, id: option.id, isParent: option.isParent})
      }
    });
  
    setActivitySelection(newSelection)
  }

  const isActivitySelected = (key) => {
    return !!activitySelection.find(x => x.key === key)
  }

  const getActivityOtherValue = (key) => {
    const activity = activitySelection.find(x => x.key === key)

    return activity?.other
  }

  const setActivityOtherValue = (key, other) => {
    const activity = activitySelection.find(x => x.key === key)
    
    if(!!activity){
      activity.other = other
    }
  }
  
  return (
    <>
      <Form onSubmit={submitForm} noValidate>
        <KioskHeader />

        <br />

        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <h4>Activity Check In</h4>
                <br />
                <Form.Group>
                  <Form.Label>
                    <RequiredIndicator />
                    What activities or events are you here for today?
                  </Form.Label>

                  {GetByGroupKey(localVocabs, 'activity-check-in-options')
                      .filter((x) => !x.parentId)
                      .map((option) => <ActivityCheckInSelectItem  
                        option={option} 
                        optionList={localVocabs} 
                        selection={activitySelection} 
                        onToggleSelection={toggleActivitySelection}
                        isActivitySelected={isActivitySelected} 
                        getOptionOther={getActivityOtherValue}
                        onSetOptionOther={setActivityOtherValue}
                        errors={errors}
                      />)
                        
                  }
                <Form.Label style={{color:"red"}}>{errors.noSelection}</Form.Label>
                </Form.Group>

                <br />
                <Button
                  variant="primary"
                  type="submit"
                >
                  {
                    loading
                    && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: 10, height: 22, width: 22 }}
                      />
                    )
                  }
                  Check In
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Form>
      <AdditionalAssessmentModal
        show={showAdditionalAssessments}
        additionalAssessmentsConfigs={addiionalAssessmentsConfigs}
        setShow={setShowAdditionalAssessments}
        redirect={redirect}
      />
    </>

  );
}

export default ActivityCheckInSelect;
